<template>
  <div>
    <Summary
      :appearance="appearance"
      :forced="forced"
    >
      <template #Card>
        <component
          :is="StyleCard"
          :collapse-button="props.collapseButton"
          :show-appt="props.showAppt"
        />
      </template>
      <template #List>
        <component
          :is="StyleList"
          :collapse-button="props.collapseButton"
          :show-appt="props.showAppt"
        />
      </template>
    </Summary>    
  </div>
</template>

<script setup>
import StyleCard from "./StyleCard.vue";
import StyleList from "./StyleList.vue";

import { defineProps } from "vue";

const props = defineProps({
  accommodation: {
    type: Object,
    default: null,
  },
  appearance: {
    type: String,
    default: null,
  },
  forced: {
    type: Boolean,
    default: false,
  },
  collapseButton: {
    type: Boolean,
    default: true,
  },
  showAppt: {
    type: Boolean,
    default: true,
  },
});

import AccommodationSetup from "./AccommodationSetup";
AccommodationSetup(props.accommodation);
</script>
