<template>
  <CardContainer
    v-slot="slotProps"
    :impose-appearance="'List'"
    :collapse-button="props.collapseButton"
    :loaded="accommodationSetup.loaded"
    class="gradient m-auto"
  >
    <TopRightButton
      v-if="$route.name !== 'Accommodation'"
      @click="accommodationSetup.goToAccomPage()"
    />

    <Image
      :col="4"
      :photo-id="accommodationSetup.accommodation.MainPhoto.Id"
    />

    <MDBCol
      col="8"
      class="d-flex flex-column p-0"
    >
      <Address
        :col="12"
        :address="accommodationSetup.accommodation.Address"
      />

      <!-- Details -->
      <MDBRow
        class="d-flex flex-row flex-grow-1 mw-100 m-0"
      >
        <AccommodationTechnical
          :col="7"
          :accommodation="accommodationSetup.accommodation"
        />

        <AccommodationAdditional
          :col="5"
          :accommodation="accommodationSetup"
          :contraction="true"
        />
      </MDBRow>
    </MDBCol>

    <MDBCollapse
      :id="slotProps.collapseId"
      v-model="slotProps.cardCollapse"
      class="p-0"
    >
      <Access
        :col="12"
        :accommodation="accommodationSetup.accommodation"
      />
    </MDBCollapse>

    <AccommodationAppointment
      v-if="props.showAppt"
      :col="12"
      :next-check="accommodationSetup.nextCheck"
      :contraction="true"
    />
  </CardContainer>
</template>

<script setup>
import {
  MDBCol,
  MDBCollapse,
  MDBRow,
} from "mdb-vue-ui-kit";

import Access from '@/components/summary/common/Access';
import AccommodationAdditional from '@/components/summary/common/AccommodationAdditional';
import AccommodationAppointment from '@/components/summary/common/AccommodationAppointment';
import AccommodationTechnical from '@/components/summary/common/AccommodationTechnical';
import Address from '@/components/summary/common/Address';
import Image from '@/components/summary/common/Image';
import TopRightButton from '@/components/summary/common/TopRightButton';

import { defineProps, inject } from 'vue'

const props = defineProps({
  collapseButton: {
    type: Boolean,
    default: true,
  },
  showAppt: {
    type: Boolean,
    default: true,
  },
});

const accommodationSetup = inject('accommodationSetup')
</script>
