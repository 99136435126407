import { onMounted, provide, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { mutateKey } from '@/helpers'

export default function Setup(accommodation) {
  const router = useRouter();
  const store = useStore();

  const loaded = ref(false);
  const checkingsList = ref(null);
  const lastCheck = ref(null);
  const lastInCheck = ref(null);
  const nextCheck = ref(null);

  const goToAccomPage = () => {
    mutateKey('accommodationChosen', accommodation);
    router.push({ name: 'Accommodation', params: { accommodationId: accommodation.Id }})
  }

  const setConst = () => {
    for (let check of checkingsList.value) {
      if (new Date(check.AppointmentDateTime) > Date.now()) {
        nextCheck.value = check;
        break
      }
    }
    for (let check of checkingsList.value.reverse()) {
      if (!lastCheck.value && new Date(check.AppointmentDateTime) < Date.now()) lastCheck.value = check
      if (new Date(check.AppointmentDateTime) < Date.now() && check.Type === 0) {
        lastInCheck.value = check;
        break
      }
    }
  };

  onMounted(async () => {
    return store.dispatch("getAllWithFilters", { AccomodationId: accommodation.Id })
    .then((res) => {
      checkingsList.value = res;
      if (checkingsList.value && checkingsList.value.length > 0) {
        setConst();
        return
      }
    })
    .then(() => { return loaded.value = true })
  });

  const accommodationSetup = reactive({
    accommodation,
    loaded,
    checkingsList,
    lastCheck,
    lastInCheck,
    nextCheck,
    goToAccomPage
  });
  provide('accommodationSetup', accommodationSetup);

  return {
    accommodationSetup
  }
}
